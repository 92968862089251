<template>
  <el-dialog title="报名身份证明材料" @close="onClose" :visible.sync="visible" custom-class="min-width" :close-on-click-modal="false" draggable>
    <el-form ref="dataFormRef" :model="form" :rules="dataRules" formDialogRef label-width="250px" v-loading="loading">
      <el-row :gutter="24">

        <el-col :span="12" class="mb20"  v-if="form.personType!='seller'">
          <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode" >
            <el-input v-model="form.unifiedSocialCreditCode" placeholder="请输入统一社会信用代码" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20"  v-if="form.personType!='seller'">
          <el-form-item label="联系人地址" prop="contactAddress">
            <el-input v-model="form.contactAddress" placeholder="请输入联系人地址" />
          </el-form-item>
        </el-col>


        <el-col :span="24" class="mb20">
          <el-divider>文件上传</el-divider>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="营业执照" prop="businessFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px">
              <div v-if="!form.fileId">
                <upload @childUpload="parentGetData" @on-preview="previewFile" name="businessFiles" />
              </div>
              <ul v-else class="navWrap_center_ul">
                <li
                    v-for="(item, index) in form.businessFiles"
                    :key="index"
                    @click="downloadFile(item.url)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="机构代码" prop="companyCode">
            <div  v-if="!form.fileId">
              <div style="display: flex; flex-direction: row; column-gap: 12px">
                <upload @childUpload="parentGetData" @on-preview="previewFile" name="companyCode" />
              </div>
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(companyCodeItem, index) in form.companyCode"
                  :key="index"
                  @click="downloadFile(companyCodeItem.url)"
              >
                {{ companyCodeItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="10" class="mb20">
          <el-form-item label="企业章程文件" prop="companyFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="!form.fileId">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="companyFiles" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(itemcompany, index) in form.companyFiles"
                  :key="index"
                  @click="downloadFile(itemcompany.url)" >
                {{ itemcompany.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="股东会决议文件" prop="shareholderFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="!form.fileId">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="shareholderFiles" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(itemShareholder, index) in form.shareholderFiles"
                  :key="index"
                  @click="downloadFile(itemShareholder.url)" >
                {{ itemShareholder.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="10" class="mb20">
          <el-form-item label="法人证明" prop="farenZhengming">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="!form.fileId">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="farenZhengming" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(farenZhengmingItem, index) in form.farenZhengming"
                  :key="index"
                  @click="downloadFile(farenZhengmingItem.url)" >
                {{ farenZhengmingItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="法人身份证" prop="farenIdCard">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="!form.fileId">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="farenIdCard" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(farenIdCardItem, index) in form.farenIdCard"
                  :key="index"
                  @click="downloadFile(farenIdCardItem.url)" >
                {{ farenIdCardItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="10" class="mb20">
          <el-form-item label="经办人委托书" prop="proxyFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="!form.fileId">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="proxyFiles" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(proxyFilesItem, index) in form.proxyFiles"
                  :key="index"
                  @click="downloadFile(proxyFilesItem.url)" >
                {{ proxyFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="缴纳保证金凭证" prop="depositFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  v-if="!form.fileId">
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="depositFiles" />
            </div>
            <ul v-else class="navWrap_center_ul">
              <li
                  v-for="(depositFilesItem, index) in form.depositFiles"
                  :key="index"
                  @click="downloadFile(depositFilesItem.url)" >
                {{ depositFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer >
			<span class="dialog-footer" v-if="!form.fileId">
				<el-button @click="onClose">取消</el-button>
				<el-button type="primary" @click="onSubmit" :disabled="loading">确认</el-button>
			</span>
    </template>

  </el-dialog>
</template>

<script setup  name="demandTradeFilesDialog">
import { useDict } from '@/hooks/dict';
import {Message} from 'element-ui'
import {reactive, ref, defineAsyncComponent, nextTick} from 'vue'
import  CommonService  from '@/service/CommonService';

const { transaction_person_type } = useDict('transaction_person_type');

const Upload = defineAsyncComponent(() => import('@/components/Upload/NewFile.vue'));

const parentGetData = (value,name) => {
  form[name] = value;
  dataFormRef.value.validateField(name);
};

const onClose= () =>{
  stop();
  setTimeout(() => {
    window.location.reload()
  }, 300);

}


// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);


// 提交表单数据
const form = reactive({
  id: '',
  landId: '',
  contactAddress: '',
  unifiedSocialCreditCode: '',
  personType: '',
  userId: localStorage.getItem('user_id'),
  companyFiles: [],
  shareholderFiles: [] ,
  depositFiles: [] ,
  farenIdCard: [] ,
  farenZhengming: [] ,
  companyCode: [] ,
  businessFiles: [] ,
  proxyFiles: [] ,
});

const previewFile = (file) => {
  window.open(file.url);
};


const validateArray = async (rule, value, callback) => {

  if (value.length === 0) {
      return callback(new Error( rule.message))
  }

  return callback;

}

const downloadFile = (file) => {
  window.open(file);
};
// 定义校验规则
const dataRules = ref({
  businessFiles: [{ required: true, validator: validateArray, message: '请上传营业执照', trigger: 'blur' }],
  companyCode: [{ required: true, validator: validateArray, message: '请上传机构代码', trigger: 'blur' }],
  companyFiles: [{ required: true, validator: validateArray, message: '请上传企业章程文件', trigger: 'blur' }],
  shareholderFiles: [{ required: true, validator: validateArray, message: '请上传股东会决议', trigger: 'blur' }],
  farenZhengming: [{ required: true, validator: validateArray, message: '请上传法人证明', trigger: 'blur' }],
  farenIdCard: [{ required: true, validator: validateArray, message: '请上传法人身份证', trigger: 'blur' }],
  depositFiles: [{ required: true, validator: validateArray, message: '请上传缴纳保证金凭证', trigger: 'blur' }],
  unifiedSocialCreditCode: [{ required: true, message: '请输入统一社会信用码', trigger: 'change' }],
 // contactAddress: [{ required: true, message: '请输入联系人地址', trigger: 'change' }]
});

// 打开弹窗
const openDialog = (param , type) => {
  form.personType = '';
  if (type === 'protocol' ||  type === 'rent') {
    form.personType = param.sellerId === localStorage.getItem('user_id') ? 'seller' : 'buyer';
    form.fileId = form.personType === 'seller' ? param.sellerIdentityId  : param.buyerIdentityId ;

  }
  visible.value = true;
  form.transactionId = param.id;
  form.transactionType = type;
  // 重置表单数据
  nextTick(() => {
    form.fileId > 0 ? '' : form.fileId = null;
    dataFormRef.value?.resetFields();
  });
  if  ( (type === 'listing'  || type === 'auction' ) && param.buyerIdentityId > 0) {
    form.fileId = param.buyerIdentityId;
  }

  if (form.fileId > 0 ) {
    getAgreementTradeFileData(form.fileId);
  }
};

// 提交
const  onSubmit = async () => {
  const valid = await dataFormRef.value.validate().catch(() => {});

  if (!valid) return false;

  if ( valid === 'undefined' || !valid) return false;
  try {
    loading.value = true;
    if (form.id) {
      await CommonService.putAgreementTradeFiles(form).then(res => {
        if (res && res.code === 0) {
          Message.success('修改资料成功');
        }else {
          Message.error(res.msg);
        }
        visible.value = false;
      });
    } else {
     await CommonService.addAgreementTradeFiles(form).then(res => {
        if (res && res.code === 0) {
          Message.success('报名成功');
        }else {
          Message.error(res.msg);
        }
       visible.value = false;
      });
    }

  } catch (err) {
    Message.error(err.msg);
  } finally {
      loading.value = false;
      // window.location.reload();
  }
};

const  sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const getAgreementTradeFileData = (id) => {
  // 获取数据
  loading.value = true;
  CommonService.getAgreementTradeFiles(id).then((res) => {
    if (res.data) {
      console.log(res.data)
      Object.assign(form, res.data);
    }
  }).finally(() => {
    loading.value = false;
  });
};



// 暴露变量
defineExpose({
  openDialog,
});
</script>
<style>
.form-item-desc {
  font-size: 12px;
  color: red;
}
.min-width{
  width: 1300px;
}
.upload-file{
  width: 150px;
}
.navWrap_center_ul {
  width: 150px;
}
</style>
